.border {
    border: 1px solid var(--alpha-100);
    padding: env(--space-8);
    border-radius: env(--radius-8);
}

.row {

    & div {
        width: 50%;
    }
}
