.card {
    & .name {
        text-transform: capitalize;
        color: var(--alpha-500)
    }

    & .logo {
        border: 1px var(--alpha-100) solid;
        inline-size: env(--space-64);
        block-size: env(--space-52);
        border-radius: env(--radius-4);
    }

    & .expiration {
        color: var(--alpha-300)
    }
}
