.otpContainer {

    & .input {
        block-size: var(--field-height);
        margin: 0;
        font-family: inherit;
        appearance: none;
        position: relative;
        display: block;
        inline-size: env(--space-64);
        block-size: env(--space-64);
        box-sizing: border-box;
        overflow: hidden;
        color: var(--alpha-500);
        background: transparent;
        padding: var(--field-padding);
        outline: none;
        border: 1px solid var(--alpha-100);
        border-radius: env(--radius-8);
        font-size: env(--font-size-20);
        line-height: env(--font-lh-24);

        font-weight: 500;
        letter-spacing: -0.01em;
        text-align: center;

        @media (--from-small) {
            inline-size: env(--space-64);
            block-size: env(--space-80);
            font-size: env(--font-size-40);
            line-height: env(--font-lh-48);
        }

        &:focus,
        &:active {
            outline: var(--primary-100) 3px solid;
        }


        &:disabled {
            outline: 0;
            color: var(--alpha-300);
            cursor: not-allowed;
            background-color: var(--alpha-50);
        }

        &::placeholder {
            color: var(--alpha-300);
        }
    }
}
