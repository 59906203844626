.tab {
    & .emptyState {
        height: calc(env(--space-200)*2.5);
        /* background-color: var(--alpha-400); */

        position: relative;
        padding: env(--space-24);
        border: 1px var(--alpha-100) solid;
        border-radius: env(--radius-12);


        & .img {
            margin-bottom: env(--space-24);
        }

        & .transLink {
            text-decoration: underline;
            color: var(--primary-500);
        }
    }
}
