.container {
    .icon {
        padding: env(--space-12);
        border-radius: 50%;
        border: 1px var(--alpha-100) solid;

        & * {
            color: var(--primary-500);

        }

        &[data-danger="true"] * {
            color: var(--error-500);
        }
    }

    & .text {
        color: var(--alpha-300)
    }
}
