.container {
    margin: env(--space-12) 0;
    padding: env(--space-24);
    border: 1px var(--alpha-100) solid;
    border-radius: env(--radius-12);
    color: var(--alpha-300);

    & .marginTop {
        margin-top: env(--space-12);
    }
}

.emptyState {
    margin-top: env(--space-12);
}
