/** Breakpoints 18
*  extra-small = 30em > 480px
*  small = 48em > 768px
*  medium = 60em > 960px
*  large = 80em > 1280px
*  extra-large = 100em > 1600px
*/
$PHONE: env(--breakpoint-small);
$TABLET: env(--breakpoint-medium);
$DESKTOP: env(--breakpoint-large);


@mixin responsiveMaxWidth($device) {

	// phones and lower
	@if $device ==phone {
		@media only screen and (max-width: $PHONE) {
			@content;
		}
	}

	// tablets and lower
	@if $device ==tablet {
		@media only screen and (max-width: $TABLET) {
			@content;
		}
	}

	// desktop and lower
	@if $device ==desktop {
		@media only screen and (max-width: $DESKTOP) {
			@content;
		}
	}
}


/**
* MOBILE FIRST media queries
*/
@mixin responsiveMinWidth($device) {

	// bigger than phone
	@if $device ==phone {
		@media only screen and (min-width: $PHONE) {
			@content;
		}
	}

	// bigger than tablet
	@if $device ==tablet {
		@media only screen and (min-width: $TABLET) {
			@content;
		}
	}

	// bigger than desktop
	@if $device ==desktop {
		@media only screen and (min-width: $DESKTOP) {
			@content;
		}
	}

	@else {
		@media only screen and (min-width: $device) {
			@content;
		}
	}
}

@mixin grayCardResponsive() {
	background-color: var(--alpha-50);
	border: solid 1px var(--alpha-100);
	padding: env(--space-32) env(--space-24);
	border-radius: env(--radius-12);
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
		0px 2px 8px 0px rgba(16, 24, 40, 0.10);

	@include responsiveMinWidth(tablet) {
		padding: env(--space-48) env(--space-40);
		border-radius: env(--radius-20);
	}
}

@mixin whiteCardResponsive() {
	background-color: var(--alpha-0);
	border: solid 1px var(--alpha-100);
	padding: env(--space-20);
	border-radius: env(--radius-12);
	min-height: calc(env(--space-160) * 3/2);
	inline-size: 100%;

	@include responsiveMinWidth(phone) {
		inline-size: calc((100% / 2) - env(--space-12));
	}

	@include responsiveMinWidth(tablet) {
		inline-size: calc((100% / 3) - (env(--space-12)));
		border-radius: env(--radius-20);
	}

	&:hover {
		box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
			0px 2px 8px 0px rgba(16, 24, 40, 0.10);

	}

}

// TODO: regroup verticals with mapped colors  confirmed by UI
@mixin SchoolVerticalColorMapping($property, $class: null, $opacity: 1) {

	&[data-vertical] {

		// fallback for no vertical or others
		@if $class !=null {
			#{$class} {
				#{$property}: rgba(map-get($path_colors, "no_vertical"), $opacity);
			}
		}

		@else {
			#{$property}: rgba(map-get($path_colors, "no_vertical"), $opacity);
		}
	}

	&[data-vertical="Design"] {
		@if $class !=null {
			#{$class} {
				#{$property}: rgba(map-get($path_colors, "design"), $opacity);
			}
		}

		@else {
			#{$property}: rgba(map-get($path_colors, "design"), $opacity);
		}
	}

	&[data-vertical="New Business Learn"] {
		@if $class !=null {
			#{$class} {
				#{$property}: rgba(map-get($path_colors, "business_innovation"), $opacity);
			}
		}

		@else {
			#{$property}: rgba(map-get($path_colors, "business_innovation"), $opacity);
		}
	}

	&[data-vertical="Career DHR"],
	&[data-vertical="Talent Acquisition"] {
		@if $class !=null {
			#{$class} {
				#{$property}: rgba(map-get($path_colors, "human_resource"), $opacity);
			}
		}

		@else {
			#{$property}: rgba(map-get($path_colors, "human_resource"), $opacity);
		}
	}

	&[data-vertical="Digital Marketing"] {
		@if $class !=null {
			#{$class} {
				#{$property}: rgba(map-get($path_colors, "marketing"), $opacity);
			}
		}

		@else {
			#{$property}: rgba(map-get($path_colors, "marketing"), $opacity);
		}
	}

	&[data-vertical="Data"],
	&[data-vertical="Deep Coding"],
	&[data-vertical="Deep Cybersecurity"] {
		@if $class !=null {
			#{$class} {
				#{$property}: rgba(map-get($path_colors, "data_and_hard_skills"), $opacity);
			}
		}

		@else {
			#{$property}: rgba(map-get($path_colors, "data_and_hard_skills"), $opacity);
		}
	}

	&[data-vertical="Digital Product Management"] {
		@if $class !=null {
			#{$class} {
				#{$property}: rgba(map-get($path_colors, "product_management"), $opacity);
			}
		}

		@else {
			#{$property}: rgba(map-get($path_colors, "product_management"), $opacity);
		}
	}

	&[data-vertical="GEDI DT"] {
		@if $class !=null {
			#{$class} {
				#{$property}: rgba(map-get($path_colors, "soft_skills"), $opacity);
			}
		}

		@else {
			#{$property}: rgba(map-get($path_colors, "soft_skills"), $opacity);
		}
	}
}
