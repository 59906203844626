.dialog {
    width: calc(env(--space-200)*3.5);
    z-index: 1111;

    & .form {
        padding: env(--space-32);
        padding-top: env(--space-16);
        word-break: break-all;
        /* display: block; */

        .question {
            color: var(--alpha-400)
        }

        .text {
            color: var(--alpha-400)
        }

        .fieldset {
            word-break: auto-phrase;
        }
    }
}
