@import "@/styles/_mixins.scss";

.header {
	width: 100%;
	padding: env(--space-4) env(--space-16) env(--space-4) 0;
	border-bottom: solid 1px var(--alpha-100);
	background-color: var(--alpha-0);
	height: var(--header-height);
	position: fixed;
	top: 0;
	z-index: var(--zIndexHeader);

	@include responsiveMinWidth(tablet) {
		padding: env(--space-16) env(--space-24) env(--space-16) 0;
		padding-left: env(--space-24);
		width: 100%;
		max-width: calc(100vw - var(--nav-width));
		transform: translateX(var(--nav-width));
		transition: all .3s cubic-bezier(.2, 0, 0, 1) 0s;

		&[data-sidebar-open="true"] {
			@include responsiveMinWidth(tablet) {
				transform: translateX(var(--open-nav-width));
			}
		}
	}

	.heading {
		&[data-open-sidebar="true"] {
			padding-left: env(--space-24);

			@include responsiveMinWidth(tablet) {
				padding-left: 0;
			}
		}

		button {
			margin: 0;
		}

		.btn {
			@include responsiveMinWidth(tablet) {
				display: none;
			}
		}
	}

	.messagesBtn {
		overflow: visible;

		.unreadBadge {
			position: absolute;
			top: calc(env(--space-4) * -1);
			right: env(--space-4);
			width: env(--space-12);
			height: env(--space-12);
			background-color: var(--primary-600);
			border-radius: 50%;
			overflow: visible;

			span.innerCount {
				color: var(--alpha-0);
			}
		}
	}

	.avatar {
		display: block;
	}

	.dropdown {
		border: 1px solid var(--alpha-100);

		&[data-no-padding="true"] {
			padding: 0;
		}

		.headerBlock {
			padding: env(--space-16) env(--space-24) 0;

			&[data-with-p-bottom="true"] {
				padding-bottom: env(--space-16);
			}

			&[data-with-p-top="false"] {
				padding-top: env(--space-4);
			}
		}

		.email {
			word-break: break-all;
		}

		.footer {
			padding: 0 env(--space-12) env(--space-16);

			.link {
				color: var(--primary-500);
				cursor: pointer;
				text-decoration: underline;
			}
		}
	}
}
