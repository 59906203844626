.pagination {
    padding: env(--space-16);

    & .pages {

        & button {
            inline-size: env(--space-40);
            block-size: env(--space-40);
            border-radius: env(--space-8);
            border: none;

            &[data-selected="true"] {
                background-color: var(--alpha-50);

            }
        }
    }
}
