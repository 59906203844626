@import "@/styles/_mixins.scss";


.container {
  min-height: 100%;
  padding-bottom: env(--space-24);

  .item {
    padding: env(--space-24);
    border: 1px var(--alpha-100) solid;
    border-radius: env(--radius-12);
  }

  .notificationsLeftCol {
    max-width: calc(env(--space-200) * 1.56);

    @include responsiveMinWidth(phone) {
      min-width: calc(env(--space-200) * 1.56);
    }
  }

}

.titleBtnWrapper {
  width: 100%;
}

.title {
  color: var(--alpha-300)
}

.subtitle {
  display: flex;
  flex-wrap: wrap;
  word-break: break-word;
}

.changeBtn {
  flex-shrink: 0;
  align-items: flex-start;
}

.dialog {
  width: calc(env(--space-200)*2.9);
  min-width: calc(env(--space-200)*2.9);

  .title {
    padding: env(--space-12);
    border-radius: env(--radius-8);
    border: 1px var(--alpha-100) solid;


    * {
      color: var(--primary-500);

    }

    &[data-danger="true"] * {
      color: var(--error-500);
    }
  }

  .content {
    padding: env(--space-32);
    padding-top: env(--space-16);
    word-break: break-all;
    /* display: block; */

    .text {
      color: var(--alpha-300)
    }

    .input {
      &[data-margin-top="false"] {
        margin-top: 0;
      }

      &[data-margin-bottom="true"],
      &.marginBottom {
        margin-bottom: env(--space-12);
      }
    }

    /* & button {
            width: 100%;
        } */

    .transLink {
      text-decoration: underline;
    }
  }
}
