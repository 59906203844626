.link {

    &[data-active="false"] {
        cursor: not-allowed;
        pointer-events: none;

        svg {
            fill: var(--alpha-200);

        }
    }

    &>* {
        margin: 0;
    }
}
