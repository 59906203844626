.tab {
    padding-top: env(--space-32);
    position: relative;
    margin-bottom: env(--space-80);

    & .container {
        padding: env(--space-24);
        border: 1px var(--alpha-100) solid;
        border-radius: env(--radius-12);
        margin-top: env(--space-64);

        & .explanation {
            color: var(--alpha-400);
        }


        & .freePlan {
            inline-size: 100%;

            @media (min-width: env(--breakpoint-medium)) {
                inline-size: 50%;
            }

            & .item {
                & span:first-child {
                    color: var(--success-500);
                }
            }
        }
    }

    & .newPlan,
    & .plan {
        position: absolute;
        right: 0;
        z-index: 10;
        max-inline-size: calc(env(--space-200)*2.25);
        min-block-size: calc(env(--space-200)*2.5);
        border-radius: env(--radius-12);
        top: env(--space-24);
        border: env(--space-2) solid var(--primary-300);
        padding: env(--space-24);
        background-color: var(--primary-50);


        & .image {
            margin-bottom: env(--space-24);
        }

        & .link {
            width: 100%;
            margin-top: env(--space-12);
        }

        & .priceLabel {
            margin-bottom: env(--space-4);
            color: var(--alpha-300)
        }

        &>div {
            width: 100%;
        }
    }

    & .plan {
        background-color: var(--alpha-0);

        box-shadow: 0px 10px 40px 0px #EE76601F;
        width: 100%;

        &>div {
            width: 100%;
        }
    }

    & .downgradeButton {

        &>* {

            color: var(--error-500);
        }
    }
}
