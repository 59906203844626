@import "@/styles/_mixins.scss";

.input {
    width: 100%;

    &[data-uppercase="true"] input:not(:placeholder-shown) {
        text-transform: uppercase;
    }

    @include responsiveMinWidth(tablet) {
        max-width: 50%;

        &[data-full-width="true"] {
            max-width: 100%;
        }

        &[data-small-width="true"] {
            max-width: 33%;
        }
    }
}
