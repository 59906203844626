@import "@/styles/_mixins.scss";

.emptyState {
    border: 1px dashed var(--alpha-100);
    border-radius: env(--radius-20);
    overflow: hidden;
    color: var(--alpha-300);
    padding: env(--space-24);
    background-color: var(--alpha-0);

    @include responsiveMinWidth(tablet) {
        flex-direction: row;
        justify-content: space-between;
    }

    .info {
        @include responsiveMinWidth(tablet) {
            flex-direction: row;
            justify-content: space-between;
        }
    }

    .description {
        margin-top: env(--space-8);
    }

    & .dot {
        width: env(--space-48);
        height: env(--space-48);
        border-radius: 50%;
        background-color: var(--alpha-50);

        @include responsiveMinWidth(tablet) {
            width: env(--space-64);
            height: env(--space-64);
        }

        &>span {
            color: var(--alpha-300);
        }

    }
}
