@import "@/styles/_mixins.scss";


.inputsContainer {
    // &[data-open="true"] {
    //     animation: slide-down 300ms ease-out;
    // }

    // &[data-open='false'] {
    //     animation: slide-up 300ms ease-out;
    // }

    .formChild {
        width: 100%;

        @include responsiveMinWidth(tablet) {
            flex-direction: row;
            gap: env(--space-24);
        }

        .fullwidth {
            width: 100%;
        }
    }

    .warning {
        color: var(--alpha-200);
    }
}



@keyframes slide-down {
    from {
        height: 0;
        opacity: 0;
    }

    to {
        height: 100%;
        opacity: 1;
    }
}

@keyframes slide-up {
    from {
        height: 100vh
    }

    to {
        height: 0;
    }
}
