@import "./_mixins.scss";

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

:root {
  --zIndexLast: 0;
  --zIndexLow: 100;
  --zIndexMid: 200;
  --zIndexMidHigh: 250;
  --zIndexHigh: 300;
  --zIndexHeader: 400;
  --header-height: env(--space-64);
  --nav-width: calc(env(--space-200) / 2.2);
  --open-nav-width: calc(env(--space-200) * 1.25);
  --layout-max-width: calc(env(--space-200) * 6);
  --channels-sidebar: calc(env(--space-200) + env(--space-144));
  /* course verticals colors in rgb */
  --header-design: 86, 187, 189;
  --header-business_innovation: 159, 66, 188;
  --header-human_resource: 203, 26, 75;
  --header-marketing: 139, 162, 47;
  --header-data_and_hard_skills: 109, 66, 180;
  --header-product_management: 159, 66, 188;
  --header-soft_skills: 203, 26, 75;
  --header-no_vertical: 114, 118, 126;

  @include responsiveMinWidth(tablet) {
    --header-height: env(--space-88);
  }
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  white-space: pre-line;
  scroll-padding-top: calc(var(--header-height)*1.5);
  /* TODO Check! */
  /* overflow-y: hidden; */
}

body {
  color: var(--alpha-500);
  background-color: var(--alpha-0);
  white-space: pre-wrap;
  display: flex;
  flex-direction: column;
}

/* a {
  color: inherit;
  text-decoration: none;
} */

/* @media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
} */

[data-radix-popper-content-wrapper] {
  z-index: 1000 !important;
}

// html body[data-iubenda="false"] a.iubenda-tp-btn:not([data-tp-nostyle]) {
//   background-color: red !important;
// }
