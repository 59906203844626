.container {
	border: 1px var(--alpha-100) solid;
	border-radius: env(--radius-12);
	margin: env(--space-12) 0;

	.table {
		width: 100%;
		color: var(--alpha-300);
		border-collapse: collapse;

		.marginTop {
			margin-top: env(--space-12);
		}

		.thead {

			th {
				padding: env(--space-8) 0;
			}

			tr {
				border-bottom: 1px var(--alpha-100) solid;
			}
		}

		th:first-child,
		td:first-child {
			padding: 0 env(--space-24) 0;
		}

		tbody {
			color: var(--alpha-500);

			td {
				padding: env(--space-12) 0;

			}
		}

		tr:not(:last-child) {
			border-bottom: 1px var(--alpha-100) solid;
		}
	}


}

.emptyState {
	margin-top: env(--space-12);
}

.cardsContainer {
	margin-top: env(--space-12);

	.cardWrapper {

		>* {
			padding: env(--space-16);
		}

		border-radius: env(--radius-12);
		border: 1px var(--alpha-100) solid;

		.cardHeader {
			color: var(--alpha-300);
			border-bottom: 1px var(--alpha-100) solid;
		}


	}
}
