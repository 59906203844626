@import "@/styles/_mixins.scss";

.container {
	height: 100%;
	overflow: hidden;

	.page {
		min-height: calc(100vh - var(--header-height));
		overflow-y: auto;
		flex: 1 1 0;
		background-color: var(--alpha-0);
		width: 100%;

		&[data-bg-color="grey"] {
			background-color: var(--alpha-50);
		}

		@include responsiveMinWidth(tablet) {
			max-width: calc(100vw - var(--nav-width));
			transition: all .3s cubic-bezier(0.2, 0, 0, 1) 0s;
			transform: translateX(var(--nav-width));

			&[data-sidebar-open="true"] {

				@include responsiveMinWidth(tablet) {
					transform: translateX(var(--open-nav-width));
				}
			}
		}

		.content {
			padding: env(--space-32) env(--space-24);
			width: 100%;
			padding-top: var(--header-height);


			&[data-full-width="true"] {
				max-width: 100%;
				margin: 0;
			}

			&[data-no-padding="true"] {
				padding: var(--header-height) 0 0 0;
			}

			&[data-overflow="hidden"] {
				overflow: hidden;
			}

			@include responsiveMinWidth(tablet) {
				padding-top: var(--header-height);
				max-width: var(--layout-max-width);
				margin: 0 auto;
			}
		}

		.footer {
			background-color: var(--alpha-0);
			width: 100%;
			// &[data-padding=true] {
			//     padding: env(--space-32) env(--space-24);

			//     @include responsiveMinWidth(tablet) {
			//          max-width: var(--layout-max-width);
			//         margin: 0 auto;
			//     }
			// }
		}
	}
}
