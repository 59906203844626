.dialog {
    width: calc(env(--space-200) * 3);
    z-index: 1000;

    &[data-dimension="small"] {
        width: calc(env(--space-200) * 2);
    }

    & .iconTitle {
        padding: env(--space-12);
        border-radius: 50%;
        border: 1px var(--alpha-100) solid;

        & * {
            color: var(--primary-500);
        }

        &[data-danger="true"] * {
            color: var(--error-500);
        }
    }

    & .content {
        padding: env(--space-32);
        padding-top: env(--space-16);

        & .text {
            color: var(--alpha-300)
        }

        & .input {
            margin-top: env(--space-24);

            &[data-margin-top="false"] {
                margin-top: 0;
            }

            &[data-margin-bottom="true"],
            &.marginBottom {
                margin-bottom: env(--space-12);
            }
        }

        .cardForm {
            margin-top: env(--space-12);
            margin-bottom: env(--space-20);
        }

        .border {
            border: 1px solid var(--alpha-100);
            padding: env(--space-8);
            border-radius: env(--radius-8);
        }

        .row {
            margin-top: env(--space-12);

            & div {
                width: 50%;
            }
        }


        & .button {
            margin-top: env(--space-24);
        }

        & button {
            width: 100%;
        }
    }
}
