@import "@/styles/_mixins.scss";

.sidebar {
	width: 100%;
	padding: env(--space-16);
	border-right: solid 1px var(--alpha-100);
	transition: all .3s cubic-bezier(0.2, 0, 0, 1) 0s;
	background-color: var(--alpha-0);
	position: fixed;
	overflow-x: hidden;
	left: 0;
	top: var(--header-height);
	bottom: 0;
	transform: translateX(-100%);
	overflow-y: auto;
	min-height: 100vh;
	padding-bottom: calc(var(--header-height) * 2);
	z-index: 101;

	&[data-open='false'] {
		.info {
			.logo {
				width: 0%;
				visibility: hidden;

				transition:
					width 200ms linear, visibility 0s 200ms;
			}
		}

		.item {
			justify-content: center;
		}
	}

	&[data-open='true'] {
		transform: translateX(0);

		a {
			width: 100%;
		}
	}


	.info {
		height: env(--space-52);

		.btn {
			display: none;

			@include responsiveMinWidth(tablet) {
				display: block;
			}
		}
	}

	.version {
		display: block;

		@include responsiveMinWidth(tablet) {
			display: none;
		}
	}

	.menu {
		flex-grow: 1;
		padding: 0;
	}

	.item {
		display: flex;
		list-style-type: none;
		width: 100%;
		justify-content: flex-start;

		&[data-disabled="true"] {
			cursor: not-allowed;

			@include responsiveMaxWidth(tablet) {
				button {
					padding: 0 env(--space-16) 0;
				}
			}
		}
	}

	.badge {
		margin-left: env(--space-8);
	}

	@include responsiveMinWidth(tablet) {
		width: var(--nav-width);
		position: fixed;
		transform: translateX(0);
		display: flex;
		top: 0;
		z-index: 0;
		padding-bottom: env(--space-16);

		&[data-open='true'] {

			.version {
				display: block;
			}

			// solo uquando aperto e disabilitato e quando è mobile
			.item[data-disabled="true"] {
				button {
					padding: 0 env(--space-16) 0;
				}
			}
		}

		// .info {
		// 	.logo {
		// 		// width: 40%;
		// 	}
		// }
	}
}
